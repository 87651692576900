			<div class="container" *ngIf="featuresContent?.feature_details">
				  <div class="row" >
					<div class="col-sm-12 col-md-12 col-lg-6">
						<div class="feature-slide-content pt-3">
							  <h2 class="font-2x mb-4">{{featuresContent?.feature_details?.heading}}</h2>
							  <p class="mb-4 font-lg">{{featuresContent?.feature_details?.sub_heading}}</p>
							  <ul class="list-unstyled">
								   <li class="font-sm text-muted mb-3" *ngFor= "let featureList of featuresContent?.feature_details?.list">
										<i class="{{featureList?.icon}} mr-3"></i>
										{{featureList?.content}}
								   </li>
							  </ul>
						</div>
				   </div>
					   <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
							<div _ngcontent-nqf-c144="" class="overlay-wrap">
								<img [src] ="featuresContent?.feature_details?.image_path" _ngcontent-nqf-c144="" width="370" height="300" alt="blog-img" class="img-fluid w-100 border-rad">
								<div _ngcontent-nqf-c144="" class="card-img-overlay primary-tp-layer pos-center text-center">	
								</div>
							</div>
						</div>
					   
				  </div>
			</div>
			<br/>
	<div class="container">
		<div class="row">
			<div class="col-sm-12 col-md-6 col-lg-6">
				<!-- Google Map -->
				<div class="google-maps">
				   <div class="mapouter">
					  <div class="gmap_canvas" style="height: 400px;">
						<iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3989.796173024006!2d-78.49508999999999!3d-0.19090700000000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMMKwMTEnMjcuMyJTIDc4wrAyOSc0Mi4zIlc!5e0!3m2!1ses-419!2sec!4v1719371581689!5m2!1ses-419!2sec" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>					  </div>
				   </div>
				</div>
			</div>
		
			<div class="col-sm-12 col-md-6 col-lg-6 tp-5 py-5 contact-home-info">
				<ul class="list-unstyled">
				   <li class="media mb-5">
					  <div class="d-flex mr-4"><i class="fa fa-phone font-3x text-primary fa-3x"></i></div>
					  <div class="media-body">
						 <h5 class="mb-3"> Llámanos o escríbenos </h5>
						 <span *ngFor="let phone of contact?.phone">
							<span *ngIf="phone?.status == 1">
							   <a href="http://wa.me/593980075407" class="d-block mb-1 text-dark">{{phone?.value}}</a>
							</span>
						 </span>
					  </div>
				   </li>
				   <li class="media">
					  <div class="d-flex mr-4"><i class="fa fa-envelope font-3x text-primary fa-3x "></i></div>
					  <div class="media-body">
						 <h5 class="mb-3"> Envíanos un correo </h5>
						 <span *ngFor="let email of contact?.email">
							<span *ngIf="email?.status == 1">
							   <a href="mailto:{{email?.value}}" class="d-block mb-1 text-dark">{{email?.value}}</a>
							</span>
						 </span>
					  </div>
				   </li>
				</ul>
			 </div>
		</div>
	</div>
	<br>
<!-- Send message section -->
<div class="section-gap style-form send-msg ">
	<div class="black-tp-layer ">
		<div class="container">
			<div class="row">
				<div class="col-sm-12 col-md-10 col-lg-7 mx-auto">
					<div angly-sendMessage></div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="follow-us text-center section-gap">
	<div class="container">
		<div class="mb-5">
			<h2> Síguenos</h2>
		</div>
		<div class="social-icon-widget">
			<ul class="list-inline mb-0">
				<li class="list-inline-item mx-2"><a href="https://www.facebook.com/Dytron.corp" class="square-60 rounded-circle bg-facebook"><i class="fab fa-facebook fa-inverse"></i></a></li>
				<li class="list-inline-item mx-2"><a href="https://www.instagram.com/dytron.io/" class="square-60 rounded-circle bg-insta"><i class="fab fa-instagram fa-inverse"></i></a></li>
				<li class="list-inline-item mx-2"><a href="https://www.dytron.io" class="square-60 rounded-circle bg-twitter"><i class="fa fa-globe fa-inverse"></i></a></li>
				<li class="list-inline-item mx-2"><a href="https://www.tiktok.com/@dytron.io" class="square-60 rounded-circle bg-tiktok"> <img class="icono-tiktok" src="assets/img/TikTok-Logo-PNG.png"/> </a></li>
				<!-- <li class="list-inline-item mx-2"><a href="https://www.egm-robotics.com/home" class="square-60 rounded-circle bg-linked"><i class="fab fa-linkedin fa-inverse"></i></a></li> -->
			</ul>
		</div>
	</div>
</div>