import {HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';




@Injectable()
export class ApiService {

baseUrl = 'https://pruebas2.egm-robotics.com';
  

  token = '' ;


  constructor(private http: HttpClient) {
  }
  setToken(token){
    this.token = token;
  }
  getToken (){
    return this.token;
  }


  autenticacion(parametros): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/servicios/autenticacion`, parametros)
      .pipe(catchError(this.handleError));
  }


  envioEmail(parametros): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/servicios/envioEmail`, parametros)
      .pipe(catchError(this.handleError));
  }

  getGeoIP(parametros): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/servicios/getGeoIP`, parametros)
      .pipe(catchError(this.handleError));
  }



  getIpAddress() {
    return this.http
      .get('https://api.ipify.org/?format=json')
      .pipe(
        catchError(this.handleError)
      );
  }

////////////////////////////////////////////////////////////////////////////////////////////////////////



getRegistros(parameters): Observable<any> {

  return this.http.get(`${this.baseUrl}/api/dytron/getRegistros`, {params: parameters})
    .pipe(catchError(this.handleError));
}


newRegistro(parametros): Observable<any> {
  return this.http.post(`${this.baseUrl}/api/dytron/newRegistro`, parametros)
    .pipe(catchError(this.handleError));
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////


  nuevaNoticia(parametros): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/servicios/newNoticia`, parametros)
      .pipe(catchError(this.handleError));
  }

  getNoticias(): Observable<any> {
    console.log(`${this.baseUrl}/api/servicios/getNoticias`);
    return this.http.get(`${this.baseUrl}/api/servicios/getNoticias`)
      .pipe(catchError(this.handleError));
  }

  deleteNoticia(parametros): Observable<any> {
    const options = {
      headers: new HttpHeaders({}),
      body: parametros
    };
    return this.http.delete(`${this.baseUrl}/api/servicios/deleteNoticia`, options)
      .pipe(catchError(this.handleError));
  }

////////////////////////////////////////////////////////////////////////////////////////////////////////////




/////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // get url
  handleError(error: HttpErrorResponse) {
    console.log('error desconocido: ', error);
    if (error.error instanceof ErrorEvent) {
    } else {
      if (error.status === 401) {
        console.error('no autorizado:');
        localStorage.removeItem('jwt');
      }
    }
    return throwError(new Error(error.statusText));
  }


}
