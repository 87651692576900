import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../core/page-title/page-title.service';
import { ChkService } from '../service/chk.service';

@Component({
  selector: 'angly-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  /* Variables */
  contact : any;
  featuresContent : any;

  lat: number = -0.2678665;
  lng: number = -78.5277175;

  constructor( private pageTitleService: PageTitleService, private service:ChkService ) {

	 /* Page title */
	 this.pageTitleService.setTitle(" Ponte en contacto con nosotros ");

	 /* Page subTitle */
	 this.pageTitleService.setSubTitle(" Recibe noticias sobre nuestros nuevos proyectos. ");

	 this.service.getContactContent().
		subscribe(response => {this.contact = response},
					 err      => console.log(err),
					 ()       => this.contact
				);

        this.service.getDytronYo().
        subscribe(response => {this.featuresContent = response},
               err => console.log(err),
               () => this.featuresContent
            );
  }

  ngOnInit() {
  }

}
