import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../service/api.service';
import {HttpParams} from '@angular/common/http';
import {FormControl, FormGroup, NgForm} from '@angular/forms';
import {Router} from '@angular/router';


@Component({
  selector: 'angly-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginFormDytron: FormGroup;
  noticiaForm: FormGroup;

  constructor( private  api: ApiService, private router: Router) { }

  ngOnInit() {

    this.loginFormDytron = new FormGroup({
      usuarioDytron: new FormControl(''),
      passwordDytron: new FormControl(''),
      // texto: new FormControl(''),
    });

  }


  login(){



    // this.api.autenticacion(form).subscribe(
    // this.api.autenticacion(form).subscribe(

    this.api.autenticacion({usuario: this.loginFormDytron.controls['usuarioDytron'].value , password: this.loginFormDytron.controls['passwordDytron'].value}).subscribe(
    // this.api.autenticacion({usuario: "adminTrataTrafico", password: "passTrata"}).subscribe(
      resp => {
        console.log(resp);
        this.api.setToken(resp.token);
        // this.router.navigate(['/adminTrata/']);
        },
      error => alert('credenciales erroneas ')
    );

  }





}
