import { Component, OnInit , Input } from '@angular/core';

@Component({
  selector: '[angly-serviceNosotros]',
  templateUrl: './service-nosotros.component.html',
  styleUrls: ['./service-nosotros.component.css']
})
export class ServiceNosotrosComponent implements OnInit {

  @Input() serviceNosotrosContent:any;

  constructor() { }

  ngOnInit(): void {
  }

}
