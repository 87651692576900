<div class='row'>


<div class="col-sm-12 col-md-6  d-flex justify-content-between mb-2 border-bottom py-3" *ngFor="let productosLista of productoslista">
	<div class="d-flex justify-content-start">

		<div class="media"> 
			<img [src]="productosLista?.image" alt="product images" class="img-fluid border-rad d-flex mr-3" alt="gallery images" width="150" height="150" />
			<div class="media-body">
				<h5 class="fw-700 mb-1"> {{productosLista?.heading}}</h5>
				<!-- <p class="m-0">{{productosLista?.sub_title}}</p> -->
			
			</div>
		</div>
	</div>

</div>
</div>