<!-- Service grid -->
<!-- Service grid -->
<div class="  team col-sm-12 col-md-4 col-xl-4 m-sm-b" *ngFor="let content of serviceGridContent ? serviceGridContent.slice(0,3):[]">
   <div class=" row text-center">
      <div class="col-sm-12 col-md-6 card border-0 border-rad overlay-wrap">        
        <img [src] ="content.image_path" width="50" height="50" class="img-fluid border-rad w-100" alt="Card image cap" />
      </div>
     <!--  <h5 class="mb-3 info-title">{{content?.title}}</h5> -->
     <div class="col-sm-12 col-md-3">
      <a href="{{content.link}}" class="mt-5 text-uppercase btn {{content?.btnBgClass}} btn-sm">
         Conoce más
      </a> 
    </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
         <br/>
         <h5 class="text-center mt-1 mb-3 info-title">{{content?.title}}</h5>
         <p class="text-center mt-3 font-small">{{content?.content}}</p>
      </div>
   </div>
  
</div>

