
<!-- service-nosotros -->
 <div class="service section-gap pt-">
   <div class="container">
       <div class="row">
           <div class="col-sm-12 col-md-3 col-xl-3 m-sm-b" *ngFor="let content of serviceNosotrosContent ? serviceNosotrosContent.slice(0,4):[]">
               <div class="info-card text-center">
                   <div class="icon-wrap mb-3 {{content?.icon?.color}}">
                       <i class="{{content?.icon?.name}} font-4x"></i>
                   </div>
                   <h5 class="mb-3 info-title">{{content?.title}}</h5>
                   <p class="mb-4 font-small">{{content?.content}}</p>
               </div>
           </div>
       </div>
   </div>
</div>