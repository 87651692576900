/*
 * Send message
 * Used in another component.
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'app/service/api.service';
import { ChkService } from '../../../service/chk.service';

@Component({
  selector: '[angly-sendMessage]',
  templateUrl: './sendMessage.component.html',
  styleUrls: ['./sendMessage.component.scss']
})
export class SendMessageComponent implements OnInit {

    mensaje: any;
    sendMessageForm : FormGroup;
    respuesta: any;

   constructor(private formBuilder : FormBuilder, private service:ChkService, private api: ApiService) {

      this.sendMessageForm = this.formBuilder.group({
         first_name : [null, [Validators.required] ],
         last_name  : [null, [Validators.required] ],
         email      : [null, [Validators.required] ],
         telefono   : ['123123',  ],
         textarea   : [null, [Validators.required] ]
      });
      
   }

   ngOnInit() {
   }

   /*
    * sendMessage
    */
  //  sendMessage(values:any)
  //  {
  //    if(this.sendMessageForm.valid)
  //    {
  //      this.mensaje = {
  //        "nombre": values.first_name+" "+values.last_name,
  //        "correo": values.email,
  //        "mensaje": values.textarea
  //      }
  //       console.log(this.mensaje)
  //       this.service.mensajeEnviar(this.mensaje).
  //       subscribe(response => {this.respuesta = response},
  //                 err      => console.log(err),
  //                 ()       => {this.respuesta
  //                             console.log(this.respuesta);

  //                             window.location.href = 'https://dytron.io';

  //                             }
  //             );

  //    } else{
  //      console.log("Error!");
  //    }
  //  }


  sendMessage(values:any)
  {
    if(this.sendMessageForm.valid)
    {
      this.mensaje = {
        "nombre": values.first_name+" "+values.last_name,
        "correo": values.email,
        "mensaje": values.textarea,
        "telefono": values.telefono,
        "tipo":"1" 
      }
     
      
      this.api.newRegistro(this.mensaje).subscribe(
        resp => {
          // this.listaRegistros = resp;
          console.log(resp);
          window.location.href = 'https://dytron.io';
        },
        error => alert('error cargando el home')
      );




    } else{
      console.log("Error!");
    }
  }

}
