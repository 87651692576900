import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'angly-productos-lista',
  templateUrl: './productos-lista.component.html',
  styleUrls: ['./productos-lista.component.css']
})
export class ProductosListaComponent implements OnInit {
  @Input() productoslista : any;
  constructor() { }

  ngOnInit(): void {
  }

}
