import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'app/service/api.service';
import { PageTitleService } from '../core/page-title/page-title.service';
import { ChkService } from '../service/chk.service';

@Component({
  selector: 'app-pantalla2',
  templateUrl: './pantalla2.component.html',
  styleUrls: ['./pantalla2.component.css']
})
export class Pantalla2Component implements OnInit {



    sendMessageForm : FormGroup;
    mensaje: any;

  constructor(private formBuilder : FormBuilder,private pageTitleService: PageTitleService, private service:ChkService,  private api: ApiService) {

    /* Page title */
			this.pageTitleService.setTitle(" Promoción Especial ");

			}


  ngOnInit(){
	this.sendMessageForm = this.formBuilder.group({
		first_name : [null, [Validators.required] ],
		last_name  : [null, [Validators.required] ],
		email      : [null, [Validators.required] ],
		telefono   : [null, [Validators.required] ],
		textarea   : ['---' , [Validators.required]]
	 });

  }

  /*
	 * Social links
	 */
	socialDetails : any = [
		{ url: 'https://www.facebook.com/', icon : 'fa-facebook'},
		{ url: '', icon : 'fa-twitter text-info'},
		{ url: '', icon : 'fa-pinterest text-danger'},
	]

	/*
	 * Classes of social ul, li
	 */
	socialsClasses : any = {ulClass:"", liClass:"", linkClass:""}


	
	sendMessage(values:any)
	{
	  if(this.sendMessageForm.valid)
	  {
		this.mensaje = {
		  "nombre": values.first_name+" "+values.last_name,
		  "correo": values.email,
		  "mensaje": values.textarea,
		  "telefono": values.telefono,
		  "tipo":"kit2" 
		}
	   
		
		this.api.newRegistro(this.mensaje).subscribe(
		  resp => {
			// this.listaRegistros = resp;
			console.log(resp);
			window.location.href = 'https://dytron.io';
		  },
		  error => alert('error cargando el home')
		);
  
  
  
  
	  } else{
		console.log("Error!", this.sendMessageForm);
	  }
	}
  
  }
  
