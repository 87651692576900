import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../../core/page-title/page-title.service';
import { ChkService } from '../../service/chk.service';
declare var $ : any;

@Component({
  selector: 'app-nosotros',
  templateUrl: './nosotros.component.html',
  styleUrls: ['./nosotros.component.scss']
})
export class NosotrosComponent implements OnInit {

  /* Variables */
  services : any;
  about    : any;
  team     : any;
  contact  : any;

  constructor(private pageTitleService: PageTitleService, private service:ChkService) { 

    /* Page title */
			this.pageTitleService.setTitle(" ¿Qué es PAILUX? ");

			/* Page subTitle */
			this.pageTitleService.setSubTitle(" En PAILUX, llevamos la energía al siguiente nivel. Somos líderes en ingeniería eléctrica, fusionando tecnología de vanguardia con una experiencia incomparable en capacitación. Nuestro compromiso es iluminar tu camino hacia el éxito, ofreciendo soluciones integrales y personalizadas que transforman ideas en realidades brillantes ");

			this.service.getServicesUs().
				 subscribe(response => {this.services = response},
									err       => console.log(err),
									()        =>this.services
							 );
			//sERVICIO QUE DA MISION Y VISION
			this.service.getAbout().
				 subscribe(response => {this.about = response},
									 err      => console.log(err),
									 ()       => this.about
							 );

			this.service.getTeam().
				 subscribe(response => {this.team = response},
									 err      => console.log(err),
									 ()       => this.team
							 );

			this.service.getContactContent().
				 subscribe(response => {this.contact = response},
									 err      => console.log(err),
									 ()       => this.contact
							 );

  }

  ngOnInit(){
  }

  /*
	 * Social links
	 */
	socialDetails : any = [
		{ url: 'https://www.facebook.com/', icon : 'fa-facebook'},
		{ url: '', icon : 'fa-twitter text-info'},
		{ url: '', icon : 'fa-pinterest text-danger'},
	]

	/*
	 * Classes of social ul, li
	 */
	socialsClasses : any = {ulClass:"", liClass:"", linkClass:""}

}
