<div class="our-mission bg-light">
  <div class="container">
    <div class="blog-alternate mb-5">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6">
          <div class="overlay-wrap">
            <img src="../../assets/img/imagenes_kits/miHogar.jpg" width="370" height="300" alt="about-img"
              class="img-fluid w-100 border-rad shadow-md" />
            <div class="card-img-overlay primary-tp-layer pos-center text-center">
              <div class="center-holder">
                <a href="javascript:void(0)" class="ih-fade-right"></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">

          <div class="border-0 border-rad p-4 card-shadow">
            <div class="mb-4" style="text-align: center;">
              <h2> Formulario </h2>
            </div>
            <form  [formGroup]="sendMessageForm" (ngSubmit)="sendMessage(sendMessageForm.value)">
              <div class="form-row">
                <div class="form-group col-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                  <label class="mb-3" for="first-name">Nombre</label>
                  <input id="first-name" type="text" class="form-control" placeholder="" formControlName="first_name"
                    required>
                </div>
                <div class="form-group col-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                  <label class="mb-3" for="last-name">Apellido</label>
                  <input id="last-name" type="text" class="form-control" placeholder="" formControlName="last_name"
                    required>
                </div>
              </div>
              <div class="form-group mb-2">
                <label class="mb-3" for="email-address">Email</label>
                <input id="email-address" type="email" class="form-control" placeholder="" formControlName="email"
                  required>
              </div>
              <div class="form-group mb-4">
                <label class="mb-3" for="phone">Teléfono</label>
                <input id="phone" class="form-control" placeholder="" type="text" formControlName="telefono"  required>
              </div>
              <!-- <div class="form-group mb-5">
                <label class="mb-3" for="message">Mensaje</label>
                <textarea id="message" class="form-control" rows="5" placeholder="" formControlName = "textarea" required></textarea>
             </div> -->
              <div class="form-group ">
                <button type="submit" class="btn btn-dark btn-chk-dark inverse">Enviar Mensaje</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class='m-4'>
      <h5 class="texto-bottom m-0">Kit de promoción para todo tu hogar, contáctanos y te diremos como puedes acceder a este beneficio. </h5>
    </div>
  </div>
</div>

<div class="follow-us text-center section-gap">
	<div class="container">
		<div class="mb-5">
			<h2> Síguenos en nuestras redes sociales</h2>
		</div>
		<div class="social-icon-widget">
			<ul class="list-inline mb-0">
				<li class="list-inline-item mx-2"><a href="https://www.facebook.com/Dytron.corp" class="square-60 rounded-circle bg-facebook"><i class="fab fa-facebook fa-inverse"></i></a></li>
				<li class="list-inline-item mx-2"><a href="https://www.instagram.com/dytron.io/" class="square-60 rounded-circle bg-insta"><i class="fab fa-instagram fa-inverse"></i></a></li>
				<li class="list-inline-item mx-2"><a href="https://www.dytron.io" class="square-60 rounded-circle bg-twitter"><i class="fa fa-globe fa-inverse"></i></a></li>
				<li class="list-inline-item mx-2"><a href="https://www.tiktok.com/@dytron.io" class="square-60 rounded-circle bg-tiktok"> <img class="icono-tiktok" src="assets/img/TikTok-Logo-PNG.png"/> </a></li>
				<!-- <li class="list-inline-item mx-2"><a href="https://www.egm-robotics.com/home" class="square-60 rounded-circle bg-linked"><i class="fab fa-linkedin fa-inverse"></i></a></li> -->
			</ul>
		</div>
	</div>
</div>