import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable()
export class AuthService {
    private SSkey = 'jwt';
    private SSautenticado = 'autenticacion';

    constructor(private router: Router) {
        this.detectAuth();
    }

    setAuth(data: any) {
        console.log('token guardado', data);
        localStorage.setItem(this.SSkey, data);
        this.detectAuth();
    }
    deleteAuth() {
        localStorage.setItem(this.SSautenticado, 'error');
        localStorage.removeItem(this.SSkey);
    }
    detectAuth() {
        if (localStorage.getItem(this.SSkey) === null || localStorage.getItem(this.SSkey) === undefined) {
            localStorage.setItem(this.SSautenticado, 'error');
            // this.appService.usuario.autenticado = false;
            console.log('error');
        } else {
            localStorage.setItem(this.SSautenticado, 'OK');
            // this.appService.usuario.autenticado = true;
            console.log('autenticacion ok');
        }

    }
}
