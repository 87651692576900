import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { MainComponent }   from './main/main.component';
import { HomeComponent } from './home/home.component';
import { NosotrosComponent } from './mas/nosotros/nosotros.component';
import { PricingComponent } from './pricing/pricing.component';
import { ContactComponent } from './contact/contact.component';
import { FeaturesComponent } from './features/features.component';
import { AboutComponent } from './about/about.component';
import { SearchComponent } from './search/search.component';
import { SupportComponent } from './support/support.component';
import { sidebarWidgetsComponent } from './sidebarWidgets/sidebarWidgets.component';
import { PrestacionesComponent } from './mas/prestaciones/prestaciones.component';
import { Pantalla1Component } from './pantalla1/pantalla1.component';
import { LoginComponent } from './session/login/login.component';
import { Pantalla2Component } from './pantalla2/pantalla2.component';


export const AppRoutes: Routes = [{
   path: '',
   redirectTo: 'home',
   pathMatch: 'full',
   },{
      path: '',
      component: MainComponent,
      children: [
         {
            path: 'home',
            component: HomeComponent
         },{
            path: 'nosotros',
            component: NosotrosComponent
         },{
            path: '',
            loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule)
         },{
            path: 'pricing',
            component: PricingComponent
         },{
            path: 'contacto',
            component: ContactComponent
         },{
            path:'features',
            component:FeaturesComponent
         },{
            path:'about',
            component:AboutComponent
         },{
            path:'search',
            component:SearchComponent
         },{
            path:'support',
            component:SupportComponent
         },{

            path:'kit1',
            component:Pantalla1Component
         },{
            path:'kit2',
            component:Pantalla2Component
         },{
            path:'login',
            component:LoginComponent

         },{
            path: '',
            loadChildren: () => import('./portfolio/portfolio.module').then(m => m.PortfolioModule)
         },
         {
            path: '',
            loadChildren: () => import('./proyectos/proyectos.module').then(m => m.ProyectosModule)
         },
         {
            path: '',
            loadChildren: () => import('./tecnologias/tecnologias.module').then(m => m.TecnologiasModule)
         },
         {
            path: '',
            loadChildren: () => import('./galeria/galeria.module').then(m => m.GaleriaModule)
         }, {
            path: '',
            loadChildren: () => import('./testimonial/testimonial.module').then(m => m.TestimonialModule)
         }, {
            path: 'sidebar-widgets',
            component:sidebarWidgetsComponent
         },{
            path: '',
            loadChildren: () => import('./session/session.module').then(m => m.SessionModule)
         },{
            path: '',
            loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
         },{
				path: 'about/:keyword',component: AboutComponent
			},
         {
            path: '',
            loadChildren: () => import('./mas/mas.module').then(m => m.MasModule)
         }


      ]
}];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
