<!-- Cart widget -->

<div class="d-flex justify-content-between mb-2 border-bottom py-3" *ngFor="let cartInfo of cart">
	<div class="d-flex justify-content-start">
		<div class="media">
			<img [src]="cartInfo?.image" alt="product images" class="img-fluid border d-flex mr-3" width="80" height="80" />
			<div class="media-body">
				<h5 class="fw-700 mb-1"> {{cartInfo?.heading}}</h5>
				<p class="m-0">{{cartInfo?.sub_title}}</p>
			
			</div>
		</div>
	</div>

</div>
